import React from "react";
import "../config/i18n";

import { useTranslation } from "react-i18next";
import PageWrapper from "../components/PageWrapper";
import { Link } from 'gatsby';

const Terms = () => {
  
const { t, i18n } = useTranslation();
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <><Link to="https://profile.thedigi.app">
            <button className="btn btn btn-dark-digi btn-sm-2 rounded-8 h-50 font-size-5">
            <i className="fa fa-lock mr-9 font-size-5 " />   {t("login")}  
          </button>
          </Link>
            </>
          ),
          footerStyle: "style6",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">
                    {t("privacy_title")}</h2>
                  <p className="font-size-7 mb-0">
                  {t("privacy_subtitle1")}
                  </p>
                  <p className="font-size-7 mb-0">
                    {t("privacy_subtitle2")}
                  </p>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <p className="font-size-5 heading-default-color mb-0">
                    {t("privacy_paragraph1")}
                    <p className="font-size-5 heading-default-color mb-0">
                    {t("privacy_paragraph2")}
                     </p>
                    </p>
                    <div className="mt-9 mt-lg-11">
                      <h2 className="font-size-10 mb-7 text-dark-digi" >{t("privacy_chapter1")}</h2>
                      <h6 className="font-size-5 mb-6" >
                        {t("privacy_subheading1")}
                        </h6>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_paragraph3")}
                        <li>{t("privacy_paragraph3_bullet1")}</li>
                        <li>{t("privacy_paragraph3_bullet2")}</li>
                        <li>{t("privacy_paragraph3_bullet3")}</li>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                        1. {t("privacy_heading1")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading1_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading1_paragraph2")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                        2. {t("privacy_heading2")}</h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph1")}
                        <li>{t("privacy_heading2_paragraph1_bullet1")} <a href="https://thedigi.app">thedigi.app</a>;</li>
                        <li>{t("privacy_heading2_paragraph1_bullet2")}</li>
                        <li>{t("privacy_heading2_paragraph1_bullet3")}</li>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph2")}
                        </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading2")}
                      </h6>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph4")} <a href="mailto:dpo@thedigi.app">dpo@thedigi.app.</a></p>
                      <h5 className="font-size-8 mb-7">
                      {t("privacy_subheading3")}
                      </h5>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading2_paragraph6")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">
                    {t("privacy_chapter2")}
                      </h2>
                      <h3 className="font-size-9 mb-7">
                      3. {t("privacy_heading3")}
                      </h3>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading4")}
                      </h6>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph1")}</p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading1")}</i></h6>                      
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph2")}
                      <ul >
                        <li>{t("privacy_heading3_paragraph2_bullet1")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet2")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet3")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet4")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet5")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet6")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet7")}</li>
                        <li>{t("privacy_heading3_paragraph2_bullet8")}</li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph3")}
                        <ul>
                          <li>{t("privacy_heading3_paragraph3_bullet1")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet2")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet3")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet4")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet5")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet6")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet7")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet8")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet9")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet10")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet11")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet12")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet13")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet14")}</li>
                          <li>{t("privacy_heading3_paragraph3_bullet15")}</li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph4")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading2")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph5")}
                        <ul>
                          <li>{t("privacy_heading3_paragraph5_bullet1")}</li>
                          <li>{t("privacy_heading3_paragraph5_bullet2")}</li>
                          <li>{t("privacy_heading3_paragraph5_bullet3")}</li>
                          <li>{t("privacy_heading3_paragraph5_bullet4")}</li>
                          </ul>
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading3")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                        {t("privacy_heading3_paragraph6")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading4")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph7")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                        {t("privacy_heading3_paragraph8")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph9")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading5")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                        {t("privacy_heading3_paragraph10")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading3_subheading6")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading3_paragraph11")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading5")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">{t("privacy_heading3_paragraph12")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      4. {t("privacy_heading4")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph1")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading4_subheading1")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph2")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading4_subheading2")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph3")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading4_subheading3")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph4")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading4_subheading4")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph6_1")} 
                      <i><a href="#use-personal-data">{t("privacy_heading5")}</a></i> {t("privacy_heading4_paragraph6_2")}                      
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading4_subheading5")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading4_paragraph7")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      <a id="use-personal-data">5. {t("privacy_heading5")}</a>
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph1")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading6")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph6")}
                     </p>
                      <h6 className="font-size-4 mb-6" >
                      {t("privacy_example1")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph7")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph8")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph8_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph8_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph8_bullet3")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading7")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph9")}
                       </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph10")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph10_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph10_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph10_bullet3")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading8")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph11")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph11_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph11_bullet2")} <a href="dpo@thedigi.app">dpo@thedigi.app.</a></li>
                        <li>{t("privacy_heading5_paragraph11_bullet3")}</li>
                        <li>{t("privacy_heading5_paragraph11_bullet4")}</li>
                        <li>{t("privacy_heading5_paragraph11_bullet5")}</li>
                        <li>{t("privacy_heading5_paragraph11_bullet6")}</li>
                        <li>{t("privacy_heading5_paragraph11_bullet7")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph12_1")}<i><a href="#use-information-marketing">{t("privacy_heading8")}</a></i> {t("privacy_heading5_paragraph12_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph13")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph13_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph13_bullet2")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading9")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph14")}
                       </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph15")}
                      </p>
                      <h6 className="font-size-4 mb-6" >
                      {t("privacy_example2")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph16")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph17")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph17_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph17_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph17_bullet3")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading10")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph18")}
                       </p>
                      <h6 className="font-size-4 mb-6" >{t("privacy_example3")}</h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph19")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph20")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph21")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph21_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph21_bullet2")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading11")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph22")}
                      </p>
                      <h6 className="font-size-4 mb-6" >{t("privacy_example4")}</h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph23")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph24")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph24_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph24_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph24_bullet3")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading12")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph25")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph26")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph26_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph26_bullet2")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading13")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph27")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph27_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph27_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph27_bullet3")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph28_1")}<i><a href="#share-personal-data">{t("privacy_heading11")}</a></i> {t("privacy_heading5_paragraph28_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading5_paragraph29")}
                      <ul>
                        <li>{t("privacy_heading5_paragraph29_bullet1")}</li>
                        <li>{t("privacy_heading5_paragraph29_bullet2")}</li>
                        <li>{t("privacy_heading5_paragraph29_bullet3")}</li>
                      </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      6. {t("privacy_heading6")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading6_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading6_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading6_paragraph3_1")}<i><a href="#my-rights">{t("privacy_heading9")}</a></i>{t("privacy_heading6_paragraph3_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading6_paragraph4")}
                      <ul>
                        <li>{t("privacy_heading6_paragraph4_bullet1")}</li>
                        <li>{t("privacy_heading6_paragraph4_bullet2")}</li>
                        <li>{t("privacy_heading6_paragraph4_bullet3")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading6_paragraph5")}
                      <ul>
                        <li>{t("privacy_heading6_paragraph5_bullet1")}</li>
                        <li>{t("privacy_heading6_paragraph5_bullet2")}</li>
                      </ul>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      <a id="carry-credit-checks">7. {t("privacy_heading7")}</a>
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading7_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading7_paragraph2")}<ul>
                        <li>{t("privacy_heading7_paragraph2_bullet1")}</li>
                        <li>{t("privacy_heading7_paragraph2_bullet2")}</li>
                        <li>{t("privacy_heading7_paragraph2_bullet3")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading7_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading7_paragraph4")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      <a id="use-information-marketing">8. {t("privacy_heading8")} </a>
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading8_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading8_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading8_paragraph3")}<i><a href="#share-personal-data">{t("privacy_heading11")}</a></i>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h2 className="font-size-10 mb-7 text-dark-digi">
                       {t("privacy_chapter3")}</h2>
                      <h3 className="font-size-9 mb-7">
                     <a id="my-rights"> 9. {t("privacy_heading9")}</a>
                      </h3>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading1")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph2")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading2")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph3")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading3")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      <ul>
                        <li>{t("privacy_heading9_paragraph3_bullet1")}</li>
                        <li>{t("privacy_heading9_paragraph3_bullet2")}</li>
                        <li>{t("privacy_heading9_paragraph3_bullet3")}</li>
                        <li>{t("privacy_heading9_paragraph3_bullet4")}</li>
                        <li>{t("privacy_heading9_paragraph3_bullet5")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph4")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading4")}</i></h6> 
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading5")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph6")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph7")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading6")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      <ul>
                        <li>{t("privacy_heading9_paragraph7_bullet1")}</li>
                        <li>{t("privacy_heading9_paragraph7_bullet2")}</li>
                        <li>{t("privacy_heading9_paragraph7_bullet3")}</li>
                        <li>{t("privacy_heading9_paragraph7_bullet4")}</li>
                        </ul>
                        {t("privacy_heading9_paragraph8")}
                         </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading7")}</i></h6>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph9")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading8")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph10")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app.</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph11")}
                      </p>
                      <h6 className="font-size-4 mb-6" ><i>{t("privacy_heading9_subheading9")}</i></h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading9_paragraph12")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      10. {t("privacy_heading10")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading10_paragraph1")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app.</a>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading10_paragraph2")}
                     </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading10_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading10_paragraph4_1")}<a href="https://edpb.europa.eu/about-edpb/board/members_en">{t("privacy_heading10_paragraph4_2")}</a>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      <a id="share-personal-data">11. {t("privacy_heading11")} </a>
                      </h3>
                      <h6 className="font-size-5 mb-6" >
                      <Link to="/partnerships">{t("privacy_subheading14")}</Link>
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph1")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading15")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph5")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph6")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading16")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph7")}
                      <ul>
                        <li>{t("privacy_heading11_paragraph7_bullet1")}</li>
                        <li>{t("privacy_heading11_paragraph7_bullet2")}</li>
                        <li>{t("privacy_heading11_paragraph7_bullet3")}</li>
                        <li>{t("privacy_heading11_paragraph7_bullet4")}</li>
                        <li>{t("privacy_heading11_paragraph7_bullet5")}</li>
                        <li>{t("privacy_heading11_paragraph7_bullet6")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                        {t("privacy_subheading17")}
                      </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph8")}
                      </p>
                      <h6 className="font-size-4 mb-6" >{t("privacy_example5")}</h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph9")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph10")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph11")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph12")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading18")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph13_1")}<i><a href="#carry-credit-checks">{t("privacy_heading7")}</a></i>{t("privacy_heading11_paragraph13_2")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading19")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph14")}
                      </p>
                      <h6 className="font-size-4 mb-6" >
                      {t("privacy_example6")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph15")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph16")}
                      </p>
                      <h6 className="font-size-5 mb-6" >
                      {t("privacy_subheading20")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph17")}
                       </p>
                      <h6 className="font-size-4 mb-6" >{t("privacy_example7")}</h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph18")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph19_1")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app</a>{t("privacy_heading11_paragraph19_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph20")}
                      <ul>
                        <li>{t("privacy_heading11_paragraph20_bullet1")}</li>
                        <li>{t("privacy_heading11_paragraph20_bullet2")}</li>
                        <li>{t("privacy_heading11_paragraph20_bullet3")}</li>
                        <li>{t("privacy_heading11_paragraph20_bullet4")}</li>
                        <li>{t("privacy_heading11_paragraph20_bullet5")}</li>
                      </ul>
                      </p>
                      <h6 className="font-size-5 mb-6" >
                        {t("privacy_subheading21")}
                      </h6> 
                      <h6 className="font-size-4 mb-6" >
                      {t("privacy_example8")}
                        </h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph21")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph22")}
                      <ul>
                        <li>{t("privacy_heading11_paragraph22_bullet1")}</li>
                        <li>{t("privacy_heading11_paragraph22_bullet2")}</li>
                        <li>{t("privacy_heading11_paragraph22_bullet3")}</li>
                        </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph23_1")}<a href="https://www.facebook.com/legal/terms/customaudience">{t("privacy_heading11_paragraph23_2")}</a>.
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph24")}
                      <ul>
                        <li>{t("privacy_heading11_paragraph24_bullet1")}</li>
                      </ul>
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph25_1")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app</a>{t("privacy_heading11_paragraph25_2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph26")}
                      </p>
                      <h6 className="font-size-5 mb-6" >{t("privacy_subheading22")}</h6> 
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading11_paragraph27")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                    <h3 className="font-size-10 mb-7 text-dark-digi">
                    {t("privacy_chapter4")}
                      </h3>
                      <h3 className="font-size-9 mb-7">
                      12. {t("privacy_heading12")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading12_paragraph1")}</p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading12_paragraph2")} </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading12_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading12_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading12_paragraph5")}<a href="mailto:dpo@thedigi.app">dpo@thedigi.app.</a>
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      13. {t("privacy_heading13")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading13_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading13_paragraph2")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading13_paragraph3")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading13_paragraph4")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading13_paragraph5")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      14. {t("privacy_heading14")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading14_paragraph1")}
                      </p>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading14_paragraph2")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      15. {t("privacy_heading15")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading15_paragraph1")}
                      </p>
                    </div>
                    <div className="mt-9 mt-lg-13">
                      <h3 className="font-size-9 mb-7">
                      16. {t("privacy_heading16")}
                      </h3>
                      <p className="font-size-5 heading-default-color">
                      {t("privacy_heading16_paragraph1")}
                       </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
